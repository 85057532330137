<template>
  <div className="animated fadeIn">
    <div id="snackbar"></div>
    <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>List of all Cinemas
        <div class="card-header-actions"></div>
      </b-card-header>
      <b-card-body>
        <v-client-table
          :columns="columns"
          :options="options"
          :theme="theme"
          v-model="data"
          id="dataTable"
        >
          <div slot="CinemaIsActive" slot-scope="props">
            <c-switch
              class="mx-1"
              color="primary"
              v-model="props.row.CinemaIsActive"
              variant="3d"
              label
              disabled
              v-bind="labelIcon"
            />
          </div>

          <div slot="IsComboAvailable" slot-scope="props">
            <c-switch
              class="mx-1"
              color="primary"
              disabled
              v-model="props.row.IsComboAvailable"
              variant="3d"
              label
              v-bind="labelIcon"
            />
          </div>
          <div slot="IsAppAvailable" slot-scope="props">
            <c-switch
              disabled
              class="mx-1"
              color="primary"
              v-model="props.row.IsAppAvailable"
              variant="3d"
              label
              v-bind="labelIcon"
            />
          </div>

          <!-- @click="getRowData(props.row)" -->
          <div slot="Action" slot-scope="props">
            <button
              class="btn btn-primary"
              @click="editCinema(props.row.CinemaID)"
            >
              Edit
            </button>
          </div>
        </v-client-table>
      </b-card-body>
    </b-card>
    <!-- cancel-variant="outline-danger"
      ok-variant="outline-success" -->
    <b-modal
      id="UpdateDetails-modal"
      ref="UpdateDetails-modal"
      :title="'Update DETAILS'"
      size="lg"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
    >
      <div class="">
        <b-row>
          <div class="col-4 mb-2">Cinema Name:</div>
          <div class="col-8 mb-2">
            <strong>{{ CinemaName }}</strong>
          </div>

          <div class="col-4 mb-2">Service Charges:</div>
          <div class="col-8 mb-2">
            <b-form-input
              type="text"
              v-model="cinema.ServiceCharges"
            ></b-form-input>
          </div>

          <div class="col-4 mb-2">VAT Charges:</div>
          <div class="col-8 mb-2">
            <b-form-input type="text" v-model="cinema.TaxesInPercetage">
            </b-form-input>
          </div>

          <div class="col-4 mb-2">Address:</div>
          <div class="col-8 mb-2">
            <b-form-input
              type="text"
              v-model="cinema.Address"
              id="address"
              placeholder="Address"
            ></b-form-input>
          </div>
        </b-row>
        <!-- <hr />
        <b-col sm="12">
          <b-form-group>
            <label for="status_modal" class="text-left px-0 col-7"
              >Status</label
            >
            <c-switch
              id="status_modal"
              class="mx-1"
              color="primary"
              v-model="data[getDataIndex].CinemaIsActive"
              variant="3d"
              label
              v-bind="labelIcon"
            />
          </b-form-group>
        </b-col>
        <hr />
        <b-row class="md-12 mx-1">
          <div class="md-6">Advance Booking Days </div>
          <div class="md-6 px-md-2 ml-auto">
            <b-form-input
              type="text"
              v-model="data[getDataIndex].AdvanceBookingDays"
            >
            </b-form-input>
          </div>
        </b-row>
        <hr />
        <b-row class="md-12 mx-1">
          <label for="combo_modal" class="text-left px-0 col-7"
            >Is Combo Available</label
          >
          <c-switch
            id="combo_modal"
            class="mx-1"
            color="primary"
            variant="3d"
            v-model="data[getDataIndex].IsComboAvailable"
            label
            v-bind="labelIcon"
          />
        </b-row>
        <hr />
        <b-row class="md-12 mx-1">
          <label for="app_modal" class="px-0 col-7">Is App Available</label>
          <c-switch
            id="status_modal"
            class="mx-1"
            color="primary"
            variant="3d"
            label
            v-model="data[getDataIndex].IsAppAvailable"
            v-bind="labelIcon"
          />
        </b-row>
        <hr />
        <b-row class="md-12 mx-1">
          <div class="md-6">UTC Diff </div>
          <div class="md-8 px-md-3 ml-auto">
            <b-form-input type="text" v-model="data[getDataIndex].UtcDiff">
            </b-form-input>
          </div>
        </b-row>
        <hr /> -->
        <!-- <b-col>
          <b-row class="md-12  mb-2">
            <div class="md-4">Service Charge </div>
            <div class="md-8 px-md-3 ml-auto">
              <b-form-input
                type="text"
                v-model="data[getDataIndex].ServiceCharge"
              >
              </b-form-input>
            </div>
          </b-row>
        </b-col> -->
        <!-- <hr /> -->
        <!-- <b-col>
          <b-row class="md-12">
            <div class="md-4">VAT Charges</div>
            <div class="md-8 px-md-3 ml-auto">
              <b-form-input
                type="text"
                v-model="data[getDataIndex].HandlingCharges"
              >
              </b-form-input>
            </div>
          </b-row>
        </b-col> -->
        <!-- <hr /> -->
        <!-- <b-col>
          <b-row class="md-12">
            <div class="md-6">Time To Go(in mins) </div>
            <div class="md-6 px-md-3 ml-auto">
              <b-form-input type="text" v-model="data[getDataIndex].TimeToGo">
              </b-form-input>
            </div>
          </b-row>
        </b-col> -->
        <!-- <hr /> -->
        <hr />
        <b-button
          @click="hideUpdateModal"
          variant="danger"
          class="pull-right ml-2 mt-2"
        >
          Close</b-button
        >
        <b-button
          @click="UpdateDetails"
          variant="success"
          class="pull-right mt-2"
        >
          Update</b-button
        >
      </div>
    </b-modal>
  </div>
</template>
<script>
import { Event } from "vue-tables-2";
import { Switch as cSwitch } from "@coreui/vue";
import CinemaService from "@/services/CinemaService";
import MasterService from "@/services/MasterService";

export default {
  name: "AllCinemas",
  components: {
    cSwitch,
    Event,
  },
  data: function () {
    return {
      getDataIndex: 0,
      singleRowData: {},
      CinemaName: "",
      cinema: {
        ServiceCharges: "",
        TaxesInPercetage: "",
        CinemaID: null,
        Address: "",
        Flag: 2,
        CinemaName:'',
      },
      columns: [
        "CinemaName",
        "CinemaIsActive",
        "AdvanceBookingDays",
        "IsComboAvailable",
        "IsAppAvailable",
        "UtcDiff",
        "ServiceCharge",
        "HandlingCharges",
        "TimeToGo",
        "Action",
      ],
      data: [],

      options: {
        headings: {
          CinemaName: "Cinema",
          CinemaIsActive: "Status",
          AdvanceBookingDays: "Advance Booking Days",
          IsComboAvailable: "Is Combo Avaliable",
          IsAppAvailable: "Is App Avaliable",
          UtcDiff: "UTC Diff",
          ServiceCharge: "Service Charge",
          HandlingCharges: "Handling Charges",
          TimeToGo: "Time To Go(in mins)",
          Action: "Action",
        },
        sortable: ["CinemaName"],
        filterable: ["CinemaName"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715",
      },
    };
  },
  mounted() {
    this.getCinemas(0);
  },
  methods: {
    UpdateModal() {
      this.$refs["UpdateDetails-modal"].show();
    },
    hideUpdateModal() {
      this.$refs["UpdateDetails-modal"].hide();
    },
    validation: function () {
      if (this.cinema.ServiceCharges == "") {
        this.showMessageSnackbar("Service Charges cannot be empty.");
        return false;
      } else if (this.cinema.TaxesInPercetage == "") {
        this.showMessageSnackbar("VAT Charges cannot be empty.");
        return false;
      } else if (this.cinema.CinemaID == 0) {
        this.showMessageSnackbar("Cinema ID cannot be empty.");
        return false;
      } else if (this.cinema.Address == "") {
        this.showMessageSnackbar("Address Cannot be empty.");
        return false;
      } else {
        return true;
      }
    },
    removeTags(str) {
      if (str === null || str === "") return false;
      else str = str.toString();
      return str.replace(/(<([^>]+)>)/gi, "");
    },
    editCinema: function (CinemaID) {
      console.log("data=>", CinemaID);
      // this.openModal("editModal");
      let payload = {
        CinemaID,
      };
      CinemaService.getCinemaByID(payload)
        .then((response) => {
          const { data } = response;
          if (data && data.Status && data.CinemaList.length > 0) {
            this.CinemaName = data.CinemaList[0].CinemaName;
            this.cinema.CinemaName = data.CinemaList[0].CinemaName;
            this.cinema.CinemaID = data.CinemaList[0].CinemaID;
            this.cinema.ServiceCharges = data.CinemaList[0].ServiceCharges;
            this.cinema.TaxesInPercetage = data.CinemaList[0].TaxesInPercetage;
            this.cinema.Flag = 2;
            this.cinema.Address = data.CinemaList[0].Address;
            // this.cinema.Address = this.removeTags(data.CinemaList[0].Address);
            this.$refs["UpdateDetails-modal"].show();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    UpdateDetails() {
      if (
        this.cinema.CinemaID == "" ||
        this.cinema.CinemaID == 0 ||
        this.cinema.ServiceCharges == "" ||
        this.cinema.TaxesInPercetage == "" ||
        this.cinema.Address == ""
      ) {
        this.showMessageSnackbar("Please Fill All Details");
        return false;
      } else {
        if (this.cinema.CinemaID) {
          console.log("payload=>", this.cinema);
          CinemaService.addCinema(this.cinema)
            .then((response) => {
              const { data } = response;
              console.log("response=>", response);
              if (data.Status) {
                this.showMessageSnackbar(data.Message);
                this.hideUpdateModal();
              } else {
                this.showMessageSnackbar(data.Message);
              }
            })
            .catch((error) => {
              console.log("Catch on AddUser-getRoles Error: ", error);
              this.showMessageSnackbar(
                "Oops! Something went wrong. kindly contact your administrator"
              );
            });
        }
      }
    },
    findDataIndex() {
      this.getDataIndex = this.data.findIndex(
        (z) => z.CinemaID == this.singleRowData.CinemaID
      );
      console.log("dataIndex", this.getDataIndex);
    },
    openModal(modal_id) {
      this.$refs[modal_id].show();
    },
    closeModal(modal_id) {
      this.$refs[modal_id].hide();
    },
    getRowData(data) {
      console.log("data=>", data);
      this.openModal("editModal");
      this.singleRowData = data;
      this.findDataIndex();
    },
    getCinemas: function (OrgID) {
      this.data = [];
      let payload = {
        OrgID,
      };
      CinemaService.getCinemas(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status && data.CinemaList.length > 0) {
            this.data = data.CinemaList.reverse();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    changeStatus: function (ID) {
      let payload = {
        TableName: "Cinema",
        ID,
      };
      MasterService.activateOrDeactivate(payload)
        .then((response) => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
        })
        .catch((error) => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    editCinemaCharges: function (CinemaChargesID) {
      this.$router.push({
        path: "/org-management/cinemas/add-cinema-charges",
        query: { CinemaChargesID },
      });
    },
    // Show Error Message
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
};
</script>
<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
</style>
